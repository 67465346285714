import { useEffect, useState } from "react"
import Heading from "../Heading/Heading"
import styles from "./why.module.css"
const Why = () => {
    let [animateWhy, setAnimateWhy] = useState(false) 
    useEffect(() => {
        let whySections = document.querySelector("#why-us")
        window.addEventListener("scroll", () => {
            if(window.scrollY >=  whySections.offsetTop -  300){
                setAnimateWhy(true)
            }else{
                setAnimateWhy(false)
            }
        }) 
    },)
  return (
    <div id="why-us" className={animateWhy ? styles.why + " " + styles.animate : styles.why}>
        <Heading sText={"Why"} text={"Why Us?"}></Heading>
        <Heading sText={"Why"} text={"لماذا نحن؟"} arabic={true}></Heading>
        <div className="container">
            <div language="en" className={styles.parts}>
                <div className={styles.part + " " + styles.reversed}>
                    <div className={styles.circle}>
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                        <g id="Flat">
                        <g id="Color">
                        <polygon fill="var(--sub-main-color)" points="45 17 32 25 19 17 19 3 45 3 45 17"/>
                        <polygon fill="var(--main-color)" points="40 3 40 20.08 32 25 24 20.08 24 3 40 3"/>
                        <path d="M32,25l6.49-4a21.36,21.36,0,0,0-13,0Z" fill="#a60416"/>
                        <circle cx="32" cy="41.5" fill="#fccd1d" r="19.5"/>
                        <circle cx="32" cy="41.5" fill="#f9a215" r="14.5"/>
                        <path d="M34.13,43.63V33H29.88a3.19,3.19,0,0,1-3.19,3.19H25.63v4.25h4.25v3.19a2.13,2.13,0,0,1-2.13,2.12H25.63V50H38.38V45.75H36.25A2.12,2.12,0,0,1,34.13,43.63Z" fill="#fccd1d"/>
                        </g>
                        </g>
                    </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>Pioneering Leadership</h3>
                        <p>
                            Our team consists of industry leaders and AI
                            experts dedicated to driving innovation and excellence in Oman.
                        </p>
                    </div>
                </div>
                <div className={styles.part}>
                    <div className={styles.circle}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 64 64" xmlSpace="preserve">
                    <g id="Layer_1">
                        <g>
                            <circle class="st0" fill="transparent" style={{opacity:"0.4"}} cx="32" cy="32" r="32"/>
                        </g>
                        <g class="st1" style={{opacity:"0.2"}}>
                            <g>
                                <path class="st2" fill="var(--main-color)" d="M27.5,34c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5s-2-4.5-4.5-4.5S27.5,31.5,27.5,34z"/>
                            </g>
                        </g>
                        <g class="st1" style={{opacity:"0.2"}}>
                            <path class="st2" fill="#231F20" d="M53.9,32.6c0-0.5-0.5-1.1-1-1.3l-4.1-1.5c-0.5-0.2-1.1-0.8-1.3-1.3l-0.7-1.6c-0.2-0.5-0.2-1.3,0-1.8l1.9-3.9
                                c0.2-0.5,0.1-1.2-0.2-1.6l-2-2c-0.4-0.4-1.2-0.5-1.6-0.2l-3.9,1.9c-0.5,0.2-1.3,0.2-1.8,0l-1.6-0.7c-0.5-0.2-1.1-0.8-1.3-1.3
                                l-1.5-4.1c-0.2-0.5-0.8-1-1.3-1c0,0-0.6-0.1-1.4-0.1s-1.4,0.1-1.4,0.1c-0.5,0-1.1,0.5-1.3,1l-1.5,4.1c-0.2,0.5-0.8,1.1-1.3,1.3
                                l-1.6,0.7c-0.5,0.2-1.3,0.2-1.8,0l-3.9-1.9c-0.5-0.2-1.2-0.1-1.6,0.2l-2,2c-0.4,0.4-0.5,1.2-0.2,1.6l1.9,3.9
                                c0.2,0.5,0.2,1.3,0,1.8l-0.7,1.6c-0.2,0.5-0.8,1.1-1.3,1.3l-4.1,1.5c-0.5,0.2-1,0.8-1,1.3c0,0-0.1,0.6-0.1,1.4
                                c0,0.8,0.1,1.4,0.1,1.4c0,0.5,0.5,1.1,1,1.3l4.1,1.5c0.5,0.2,1.1,0.8,1.3,1.3l0.7,1.6c0.2,0.5,0.2,1.3,0,1.8l-1.9,3.9
                                c-0.2,0.5-0.1,1.2,0.2,1.6l2,2c0.4,0.4,1.2,0.5,1.6,0.2l3.9-1.9c0.5-0.2,1.3-0.2,1.8,0l1.6,0.7c0.5,0.2,1.1,0.8,1.3,1.3l1.5,4.1
                                c0.2,0.5,0.8,1,1.3,1c0,0,0.6,0.1,1.4,0.1s1.4-0.1,1.4-0.1c0.5,0,1.1-0.5,1.3-1l1.5-4.1c0.2-0.5,0.8-1.1,1.3-1.3l1.6-0.7
                                c0.5-0.2,1.3-0.2,1.8,0l3.9,1.9c0.5,0.2,1.2,0.1,1.6-0.2l2-2c0.4-0.4,0.5-1.2,0.2-1.6l-1.9-3.9c-0.2-0.5-0.2-1.3,0-1.8l0.7-1.6
                                c0.2-0.5,0.8-1.1,1.3-1.3l4.1-1.5c0.5-0.2,1-0.8,1-1.3c0,0,0.1-0.6,0.1-1.4C54,33.2,53.9,32.6,53.9,32.6z M32,44
                                c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10s10,4.5,10,10C42,39.5,37.5,44,32,44z"/>
                        </g>
                        <g>
                            <g>
                                <path class="st3" fill="#ffc10d" d="M27.5,32c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5s-2-4.5-4.5-4.5S27.5,29.5,27.5,32z"/>
                            </g>
                        </g>
                        <g>
                            <path class="st4" fill="var(--main-color)" d="M53.9,30.6c0-0.5-0.5-1.1-1-1.3l-4.1-1.5c-0.5-0.2-1.1-0.8-1.3-1.3l-0.7-1.6c-0.2-0.5-0.2-1.3,0-1.8l1.9-3.9
                                c0.2-0.5,0.1-1.2-0.2-1.6l-2-2c-0.4-0.4-1.2-0.5-1.6-0.2l-3.9,1.9c-0.5,0.2-1.3,0.2-1.8,0l-1.6-0.7c-0.5-0.2-1.1-0.8-1.3-1.3
                                l-1.5-4.1c-0.2-0.5-0.8-1-1.3-1c0,0-0.6-0.1-1.4-0.1s-1.4,0.1-1.4,0.1c-0.5,0-1.1,0.5-1.3,1l-1.5,4.1c-0.2,0.5-0.8,1.1-1.3,1.3
                                l-1.6,0.7c-0.5,0.2-1.3,0.2-1.8,0l-3.9-1.9c-0.5-0.2-1.2-0.1-1.6,0.2l-2,2c-0.4,0.4-0.5,1.2-0.2,1.6l1.9,3.9
                                c0.2,0.5,0.2,1.3,0,1.8l-0.7,1.6c-0.2,0.5-0.8,1.1-1.3,1.3l-4.1,1.5c-0.5,0.2-1,0.8-1,1.3c0,0-0.1,0.6-0.1,1.4
                                c0,0.8,0.1,1.4,0.1,1.4c0,0.5,0.5,1.1,1,1.3l4.1,1.5c0.5,0.2,1.1,0.8,1.3,1.3l0.7,1.6c0.2,0.5,0.2,1.3,0,1.8l-1.9,3.9
                                c-0.2,0.5-0.1,1.2,0.2,1.6l2,2c0.4,0.4,1.2,0.5,1.6,0.2l3.9-1.9c0.5-0.2,1.3-0.2,1.8,0l1.6,0.7c0.5,0.2,1.1,0.8,1.3,1.3l1.5,4.1
                                c0.2,0.5,0.8,1,1.3,1c0,0,0.6,0.1,1.4,0.1s1.4-0.1,1.4-0.1c0.5,0,1.1-0.5,1.3-1l1.5-4.1c0.2-0.5,0.8-1.1,1.3-1.3l1.6-0.7
                                c0.5-0.2,1.3-0.2,1.8,0l3.9,1.9c0.5,0.2,1.2,0.1,1.6-0.2l2-2c0.4-0.4,0.5-1.2,0.2-1.6l-1.9-3.9c-0.2-0.5-0.2-1.3,0-1.8l0.7-1.6
                                c0.2-0.5,0.8-1.1,1.3-1.3l4.1-1.5c0.5-0.2,1-0.8,1-1.3c0,0,0.1-0.6,0.1-1.4C54,31.2,53.9,30.6,53.9,30.6z M32,42
                                c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10s10,4.5,10,10C42,37.5,37.5,42,32,42z"/>
                        </g>
                    </g>
                    <g id="Layer_2">
                    </g>
                    </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>Customized Approach</h3>
                        <p>
                            We understand that one size does not fit all.
                            Our solutions are tailored to meet the specific needs of our Omani
                            clients.
                        </p>
                    </div>
                </div>
                <div className={styles.part + " " + styles.reversed}>
                    <div className={styles.circle}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 500 500" xmlSpace="preserve">
                    <g id="border">
                        <path class="st0" style={{fill:"#FFFFFF",stroke:"#FFFFFF",strokeWidth:"10",strokeMiterlimit:"10"}} d="M439.3,188.3c-19.7-32.9-39.1-46.2-51.3-51.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.7-0.3-1.4-0.6-2-0.8
                            c-0.1,0-0.1,0-0.2-0.1c-0.6-0.2-1.2-0.5-1.8-0.6c-0.1,0-0.1,0-0.2-0.1c-0.6-0.2-1.2-0.4-1.7-0.5c0,0,0,0-0.1,0
                            c-0.5-0.2-1-0.3-1.5-0.4c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.9-0.2-1.3-0.3c0,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.2-1.1-0.2c0,0,0,0,0,0
                            c-0.3-0.1-0.6-0.1-0.9-0.2c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.7-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.6-0.1-0.9-0.1-0.9-0.1
                            c-15.4-38.1-83.6-54.1-83.6-54.1l-42.9-1.4c-10.1,0.6-45.9,8.8-84.8,25.7v0c0,0,0,0,0,0c-1.6,0.7-3.3,1.4-4.9,2.2c0,0,0,0,0,0v0
                            c-32.9,14.8-67.4,35.7-90.1,63.4v0c0,0,0,0,0,0c-1.4,1.6-2.7,3.3-3.9,5c0,0,0,0,0,0l0,0c-17,22.7-25.9,49.7-20.2,81.3
                            c1.2,6.5,2.5,12.5,3.8,18.1l0,0c0,0,0,0,0,0c24,99.3,75.5,77.5,78,76.4c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0s54.6,44,98.9,31.9
                            c20.1-5.5,29.5-10.1,33.9-13.6h0c0,0,0,0,0,0c2.7-2.1,3.5-3.7,3.6-4.9c0,0,0,0,0,0c0-0.2,0-0.3,0-0.4v0l0,0
                            c-0.1-0.8-0.4-1.2-0.4-1.2c20.1,9.4,31.2,13.2,44.8,26.5c13.7,13.3,34.3,33.9,34.3,33.9l18.6-5.1l-14.8-31.1c0,0,23.6,13,57.6-22.6
                            c25.3-26.4,24.9-50.9,23-61.7h0c0,0,0,0,0,0c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.1,0-0.2c-0.2-0.8-0.3-1.5-0.5-2.1
                            c0-0.1,0-0.2-0.1-0.2c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1,0-0.1-0.1-0.2
                            c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0h0c-0.1-0.4-0.2-0.7-0.2-0.7C455,273.1,471.6,242.4,439.3,188.3z"/>
                    </g>
                    <g id="object" xmlnsCc="http://creativecommons.org/ns#" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape" xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlnsSvg="http://www.w3.org/2000/svg">
                        <g>
                            <path class="st1" style={{fill:"var(--main-color)"}} d="M126.8,349.9c0,0-59.6,28.4-82-94.5s177.7-175.9,204-177.5l42.9,1.4c0,0,68.2,16,83.6,54.1
                                c0,0,31.5,0.9,63.9,55s15.7,84.8-14.4,107.5c0,0,12.5,32-21.6,67.5s-57.6,22.6-57.6,22.6l14.8,31.1l-18.6,5.1
                                c0,0-20.6-20.6-34.3-33.9c-13.7-13.3-24.7-17.1-44.8-26.5c0,0,7.2,8-37.1,20.1C181.4,393.9,126.8,349.9,126.8,349.9z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M380.9,137.7c6.4,5.6,10.2,9,6.2,19.4c-2.2,5.6-15.6,8.7-26.6,9.7c-1.2-2.4-3.6-4-6.4-4
                                c-3.9,0-7.1,3.2-7.1,7.1c0,3.9,3.2,7.1,7.1,7.1c3.1,0,5.8-2,6.7-4.8c10-0.9,27.7-3.9,31.3-13.2c4.4-11.4,0.9-17.3-4.1-22.2
                                c-5.9-2.6-10-3.3-11.8-3.5C377.8,134.9,379.4,136.3,380.9,137.7z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M426.4,301.7c-0.4-2.5-0.9-4.3-1.2-5.2c-5,0.4-19.5,0.6-30.2-1.9c-10.7-2.5-19.2-9.6-23.6-13.9
                                c0.2-0.7,0.4-1.5,0.4-2.3c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1c0,3.9,3.2,7.1,7.1,7.1c1.2,0,2.3-0.3,3.2-0.8
                                c4.9,4.7,14.1,12.2,25.9,15c7.4,1.8,16.6,2.3,23.7,2.3C421.2,302,424.5,301.8,426.4,301.7z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M271.9,277.1c-3.2,0-5.9,2.2-6.8,5.1c-8.5,2.1-19.6,2.7-32.2-8c-6.5-5.5-12-18.2-14.1-23.5
                                c2.2-1.2,3.8-3.5,3.8-6.2c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1c0,3.1,2.1,5.8,4.9,6.7c1.7,4.5,7.9,20.1,16.2,27.1
                                c8.8,7.4,17,10,24.3,10.4c0,0.1-0.1,0.1-0.1,0.2c-3.5,7.3-6.9,14.1-22.6,14.6c-11.5,0.3-23.3-7.7-23.4-7.8l-3.1,4.4c0,0,0,0,0,0
                                c-5,5.3-12.2,4.3-26.3-2.8c-5.5-2.8-9.4-7.6-11.7-11.5c1.1-1.2,1.8-2.9,1.8-4.6c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1
                                c0,3.9,3.2,7.1,7.1,7.1c0.2,0,0.3,0,0.5-0.1c2.7,4.5,7.2,10.4,14.1,13.8c2.1,1.1,4.3,2.1,6.5,3.1c-0.8,8.4-8.1,21.5-23.2,33.5
                                c-9.1,7.3-32.4,11.1-32.6,11.1l0,0.1c0.3-0.1,0.4-0.2,0.4-0.2s2.1,1.7,5.8,4.3c7.9-1.7,22.4-5.4,29.7-11.2
                                c13.9-11,23.3-24.7,25-35.8c3.1,1,6.2,1.7,9.1,1.7c4.7,0,9-1.6,12.6-6c4.9,2.6,12.8,6,21,6c0.3,0,0.6,0,0.8,0
                                c19-0.5,23.6-10,27.3-17.6c0.5-0.9,0.9-1.8,1.3-2.7c2.1-0.2,4.1-0.6,5.9-1c1.2,2.2,3.5,3.7,6.2,3.7c3.9,0,7.1-3.2,7.1-7.1
                                C279,280.3,275.8,277.1,271.9,277.1z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M241.9,355.8c-2.6-1.7-5.4-5.6-7.5-9.1c1-1.2,1.7-2.8,1.7-4.5c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1
                                c0,3.9,3.2,7.1,7.1,7.1c0.2,0,0.4,0,0.7-0.1c2.3,3.9,5.6,8.7,9.3,11.1c6.8,4.5,14.3,7.3,20.7,7.9c3.1-2.4,3.6-4.2,3.6-5.3
                                C258.1,363.2,250,361.2,241.9,355.8z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M251.3,134.6c-3.9,0-7.1,3.2-7.1,7.1c0,1.2,0.3,2.4,0.9,3.4c-3.1,2-8,3.5-13.8,3.4
                                c-4.6-0.1-20.1-1.7-24.6-19.4c4.6-3.2,7.7-7.5,8.4-11.6c0.1,0,0.2,0,0.3,0c3.9,0,7.1-3.2,7.1-7.1c0-3.9-3.2-7.1-7.1-7.1
                                c-3.9,0-7.1,3.2-7.1,7.1c0,1.9,0.8,3.7,2,4.9l-0.5,0.1c0.4,3.4-4,9-11.2,11.9c-3.7,1.5-16.4,5.3-25.9-7.5
                                c-4.5-6-7.6-13.7-8.6-16.4c-1.6,0.7-3.3,1.4-5,2.2c1.2,3.2,4.4,11,9.2,17.5c5.6,7.5,13.2,11.5,21.4,11.5c3.5,0,7.2-0.7,10.8-2.2
                                c0.4-0.2,0.8-0.4,1.2-0.5c4,13.6,14.9,21.8,29.4,22c0.2,0,0.4,0,0.6,0c7.2,0,13.6-2.1,17.7-5.4c0.6,0.1,1.2,0.2,1.8,0.2
                                c3.9,0,7.1-3.2,7.1-7.1C258.4,137.8,255.2,134.6,251.3,134.6z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M102.8,192c-2.9,0-5.4,1.7-6.5,4.2c-4.6,0.7-10.4-0.1-17.3-7.3c-5.2-5.4-9.9-19.1-10.1-19.8
                                c-1.4,1.6-2.7,3.3-3.9,5c1.9,4.9,5.7,13.9,10.1,18.5c7,7.3,13.3,9.2,18.5,9.2c0.9,0,1.7-0.1,2.5-0.2c1,2.7,3.6,4.6,6.6,4.6
                                c3.9,0,7.1-3.2,7.1-7.1C109.9,195.2,106.7,192,102.8,192z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M111.3,259c-3.9,0-7.1,3.2-7.1,7.1c0,0.4,0.1,0.8,0.1,1.2c-5.4,1.7-12.4,2.8-18.4-2.7
                                c-3.6-3.3-5.1-9.1-5.8-12.7c1.6-1.3,2.6-3.3,2.6-5.5c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1c0,3.7,2.8,6.7,6.4,7
                                c0.8,4,2.6,10.1,6.4,14.2c-2.7,5.3-7.2,13.1-16.9,13.1c-0.1,0-0.2,0-0.2,0c-7.2-0.1-15-7.5-15.1-7.6l-0.3,0.4
                                c0.6,2.7,1.3,5.3,2,7.7c3.5,2.4,8.4,4.8,13.4,4.9c0.1,0,0.2,0,0.3,0c12.2,0,18.1-9.2,21.2-15.1c3.6,2.2,7.3,3,10.8,3
                                c4.1,0,7.9-1.1,10.9-2.1c1.1,0.8,2.5,1.2,4,1.2c3.9,0,7.1-3.2,7.1-7.1C118.4,262.2,115.2,259,111.3,259z"/>
                        </g>
                    </g>
                    </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>Ethical Framework</h3>
                        <p>
                            We prioritize the ethical use of AI, ensuring our
                            solutions are developed and implemented with the highest standards
                            of integrity and responsibility
                        </p>
                    </div>
                </div>
                <div className={styles.part}>
                    <div className={styles.circle}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 392.533 392.533" xmlSpace="preserve">
                            <path style={{fill:"#FFFFFF"}} d="M196.267,21.786c-96.194,0-174.481,78.287-174.481,174.481c0,15.321,2.004,30.19,5.689,44.347h114.23
                                c5.107-25.406,27.604-44.671,54.497-44.671c7.046,0,13.77,1.293,19.911,3.685l51.459-82.23c3.168-5.107,9.891-6.659,14.998-3.491
                                s6.659,9.891,3.491,14.998l-51.459,82.36c8.145,7.758,13.834,17.842,16.162,29.22h114.23c3.685-14.222,5.689-29.026,5.689-44.347
                                C370.747,100.073,292.461,21.786,196.267,21.786z"/>
                            <path style={{fill:"#FFC10D"}} d="M345.406,200.339c0,13.77-2.004,27.087-5.495,39.758h-89.277
                                c-2.327-11.184-8.081-21.139-16.032-28.768l51.523-82.295c0.323-0.453,0.517-1.034,0.776-1.552
                                c13.511,17.648,21.657,39.24,21.657,62.642c0,6.012,4.848,10.861,10.925,10.861c6.012,0,10.925-4.848,10.925-10.861
                                c0-70.788-60.121-128.453-134.077-128.453S62.19,119.337,62.19,190.19c0,6.012,4.848,10.861,10.925,10.861
                                c6.012,0,10.925-4.848,10.925-10.861c0-58.828,50.36-106.537,112.291-106.537c29.931,0,57.018,11.184,77.188,29.35
                                c-2.327,0.776-4.396,2.327-5.818,4.525l-51.394,82.23c-6.206-2.392-12.929-3.685-19.911-3.685
                                c-26.699,0-49.131,18.941-54.368,44.154H52.622c-3.491-12.671-5.495-25.988-5.495-39.758c0-82.36,66.78-149.139,149.139-149.139
                                S345.406,117.98,345.406,200.339z"/>
                            <path style={{fill:"#FFFFFF"}} d="M196.267,217.729c-14.804,0-27.41,9.568-32,22.885h63.935
                                C223.677,227.426,211.135,217.729,196.267,217.729z"/>
                            <path style={{fill:"#56ACE0"}} d="M34.909,262.465c26.117,63.418,88.566,108.283,161.358,108.283s135.24-44.8,161.358-108.283
                                L34.909,262.465L34.909,262.465z"/>
                            <g>
                                <path style={{fill:"#194F82"}} d="M196.267,0C87.984,0,0,87.984,0,196.267s87.984,196.267,196.267,196.267
                                    s196.267-87.984,196.267-196.267S304.549,0,196.267,0z M196.267,370.747c-72.792,0-135.24-44.8-161.358-108.283h322.78
                                    C331.507,325.947,269.059,370.747,196.267,370.747z M164.267,240.614c4.59-13.317,17.131-22.885,32-22.885
                                    c14.869,0,27.41,9.568,31.935,22.885H164.267z M250.764,240.614c-2.263-11.378-8.016-21.463-16.162-29.22l51.523-82.36
                                    c0.323-0.453,0.517-1.034,0.776-1.552c13.511,17.648,21.657,39.24,21.657,62.642c0,6.012,4.848,10.925,10.925,10.925
                                    c6.077,0,10.925-4.848,10.925-10.925c0-70.788-60.121-128.453-134.077-128.453S62.19,119.273,62.19,190.19
                                    c0,6.012,4.848,10.925,10.925,10.925s10.925-4.848,10.925-10.925c0-58.828,50.36-106.537,112.291-106.537
                                    c29.931,0,57.018,11.184,77.188,29.35c-2.327,0.776-4.396,2.327-5.818,4.59l-51.394,82.166c-6.206-2.392-12.929-3.685-19.911-3.685
                                    c-26.893,0-49.455,19.265-54.497,44.671H27.604c-3.685-14.222-5.689-29.026-5.689-44.347
                                    C21.851,100.137,100.137,21.85,196.331,21.85s174.416,78.222,174.416,174.416c0,15.321-2.004,30.19-5.689,44.347H250.764z"/>
                                <path style={{fill:"#194F82"}} d="M131.62,321.358h129.293c6.012,0,10.925-4.848,10.925-10.925c0-6.012-4.848-10.925-10.925-10.925
                                    H131.62c-6.012,0-10.925,4.848-10.925,10.925S125.543,321.358,131.62,321.358z"/>
                            </g>
                        </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>Impact-Driven</h3>
                        <p>
                            We are committed to creating solutions that not only
                            solve immediate problems but also have a lasting positive impact on
                            Omani society and the economy
                        </p>
                    </div>
                </div>
            </div>
            
            <div language="ar" className={styles.parts}>
                <div className={styles.part + " " + styles.reversed}>
                    <div className={styles.circle}>
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                        <g id="Flat">
                        <g id="Color">
                        <polygon fill="var(--sub-main-color)" points="45 17 32 25 19 17 19 3 45 3 45 17"/>
                        <polygon fill="var(--main-color)" points="40 3 40 20.08 32 25 24 20.08 24 3 40 3"/>
                        <path d="M32,25l6.49-4a21.36,21.36,0,0,0-13,0Z" fill="#a60416"/>
                        <circle cx="32" cy="41.5" fill="#fccd1d" r="19.5"/>
                        <circle cx="32" cy="41.5" fill="#f9a215" r="14.5"/>
                        <path d="M34.13,43.63V33H29.88a3.19,3.19,0,0,1-3.19,3.19H25.63v4.25h4.25v3.19a2.13,2.13,0,0,1-2.13,2.12H25.63V50H38.38V45.75H36.25A2.12,2.12,0,0,1,34.13,43.63Z" fill="#fccd1d"/>
                        </g>
                        </g>
                    </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>قيادة رائدة</h3>
                        <p>
                            فريقنا يتألف من قادة في الصناعة وخبراء في الذكاء الاصطناعي ملتزمون بدفع الابتكار والتميز في عُمان.
                        </p>
                    </div>
                </div>
                <div className={styles.part}>
                    <div className={styles.circle}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 64 64" xmlSpace="preserve">
                    <g id="Layer_1">
                        <g>
                            <circle class="st0" fill="transparent" style={{opacity:"0.4"}} cx="32" cy="32" r="32"/>
                        </g>
                        <g class="st1" style={{opacity:"0.2"}}>
                            <g>
                                <path class="st2" fill="var(--main-color)" d="M27.5,34c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5s-2-4.5-4.5-4.5S27.5,31.5,27.5,34z"/>
                            </g>
                        </g>
                        <g class="st1" style={{opacity:"0.2"}}>
                            <path class="st2" fill="#231F20" d="M53.9,32.6c0-0.5-0.5-1.1-1-1.3l-4.1-1.5c-0.5-0.2-1.1-0.8-1.3-1.3l-0.7-1.6c-0.2-0.5-0.2-1.3,0-1.8l1.9-3.9
                                c0.2-0.5,0.1-1.2-0.2-1.6l-2-2c-0.4-0.4-1.2-0.5-1.6-0.2l-3.9,1.9c-0.5,0.2-1.3,0.2-1.8,0l-1.6-0.7c-0.5-0.2-1.1-0.8-1.3-1.3
                                l-1.5-4.1c-0.2-0.5-0.8-1-1.3-1c0,0-0.6-0.1-1.4-0.1s-1.4,0.1-1.4,0.1c-0.5,0-1.1,0.5-1.3,1l-1.5,4.1c-0.2,0.5-0.8,1.1-1.3,1.3
                                l-1.6,0.7c-0.5,0.2-1.3,0.2-1.8,0l-3.9-1.9c-0.5-0.2-1.2-0.1-1.6,0.2l-2,2c-0.4,0.4-0.5,1.2-0.2,1.6l1.9,3.9
                                c0.2,0.5,0.2,1.3,0,1.8l-0.7,1.6c-0.2,0.5-0.8,1.1-1.3,1.3l-4.1,1.5c-0.5,0.2-1,0.8-1,1.3c0,0-0.1,0.6-0.1,1.4
                                c0,0.8,0.1,1.4,0.1,1.4c0,0.5,0.5,1.1,1,1.3l4.1,1.5c0.5,0.2,1.1,0.8,1.3,1.3l0.7,1.6c0.2,0.5,0.2,1.3,0,1.8l-1.9,3.9
                                c-0.2,0.5-0.1,1.2,0.2,1.6l2,2c0.4,0.4,1.2,0.5,1.6,0.2l3.9-1.9c0.5-0.2,1.3-0.2,1.8,0l1.6,0.7c0.5,0.2,1.1,0.8,1.3,1.3l1.5,4.1
                                c0.2,0.5,0.8,1,1.3,1c0,0,0.6,0.1,1.4,0.1s1.4-0.1,1.4-0.1c0.5,0,1.1-0.5,1.3-1l1.5-4.1c0.2-0.5,0.8-1.1,1.3-1.3l1.6-0.7
                                c0.5-0.2,1.3-0.2,1.8,0l3.9,1.9c0.5,0.2,1.2,0.1,1.6-0.2l2-2c0.4-0.4,0.5-1.2,0.2-1.6l-1.9-3.9c-0.2-0.5-0.2-1.3,0-1.8l0.7-1.6
                                c0.2-0.5,0.8-1.1,1.3-1.3l4.1-1.5c0.5-0.2,1-0.8,1-1.3c0,0,0.1-0.6,0.1-1.4C54,33.2,53.9,32.6,53.9,32.6z M32,44
                                c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10s10,4.5,10,10C42,39.5,37.5,44,32,44z"/>
                        </g>
                        <g>
                            <g>
                                <path class="st3" fill="#ffc10d" d="M27.5,32c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5s-2-4.5-4.5-4.5S27.5,29.5,27.5,32z"/>
                            </g>
                        </g>
                        <g>
                            <path class="st4" fill="var(--main-color)" d="M53.9,30.6c0-0.5-0.5-1.1-1-1.3l-4.1-1.5c-0.5-0.2-1.1-0.8-1.3-1.3l-0.7-1.6c-0.2-0.5-0.2-1.3,0-1.8l1.9-3.9
                                c0.2-0.5,0.1-1.2-0.2-1.6l-2-2c-0.4-0.4-1.2-0.5-1.6-0.2l-3.9,1.9c-0.5,0.2-1.3,0.2-1.8,0l-1.6-0.7c-0.5-0.2-1.1-0.8-1.3-1.3
                                l-1.5-4.1c-0.2-0.5-0.8-1-1.3-1c0,0-0.6-0.1-1.4-0.1s-1.4,0.1-1.4,0.1c-0.5,0-1.1,0.5-1.3,1l-1.5,4.1c-0.2,0.5-0.8,1.1-1.3,1.3
                                l-1.6,0.7c-0.5,0.2-1.3,0.2-1.8,0l-3.9-1.9c-0.5-0.2-1.2-0.1-1.6,0.2l-2,2c-0.4,0.4-0.5,1.2-0.2,1.6l1.9,3.9
                                c0.2,0.5,0.2,1.3,0,1.8l-0.7,1.6c-0.2,0.5-0.8,1.1-1.3,1.3l-4.1,1.5c-0.5,0.2-1,0.8-1,1.3c0,0-0.1,0.6-0.1,1.4
                                c0,0.8,0.1,1.4,0.1,1.4c0,0.5,0.5,1.1,1,1.3l4.1,1.5c0.5,0.2,1.1,0.8,1.3,1.3l0.7,1.6c0.2,0.5,0.2,1.3,0,1.8l-1.9,3.9
                                c-0.2,0.5-0.1,1.2,0.2,1.6l2,2c0.4,0.4,1.2,0.5,1.6,0.2l3.9-1.9c0.5-0.2,1.3-0.2,1.8,0l1.6,0.7c0.5,0.2,1.1,0.8,1.3,1.3l1.5,4.1
                                c0.2,0.5,0.8,1,1.3,1c0,0,0.6,0.1,1.4,0.1s1.4-0.1,1.4-0.1c0.5,0,1.1-0.5,1.3-1l1.5-4.1c0.2-0.5,0.8-1.1,1.3-1.3l1.6-0.7
                                c0.5-0.2,1.3-0.2,1.8,0l3.9,1.9c0.5,0.2,1.2,0.1,1.6-0.2l2-2c0.4-0.4,0.5-1.2,0.2-1.6l-1.9-3.9c-0.2-0.5-0.2-1.3,0-1.8l0.7-1.6
                                c0.2-0.5,0.8-1.1,1.3-1.3l4.1-1.5c0.5-0.2,1-0.8,1-1.3c0,0,0.1-0.6,0.1-1.4C54,31.2,53.9,30.6,53.9,30.6z M32,42
                                c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10s10,4.5,10,10C42,37.5,37.5,42,32,42z"/>
                        </g>
                    </g>
                    <g id="Layer_2">
                    </g>
                    </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>نهج مخصص</h3>
                        <p>
                            نحن ندرك أن الحجم الواحد لا يناسب الجميع.
                            حلولنا مصممة لتلبية احتياجات عملائنا في عُمان بشكل محدد.
                        </p>
                    </div>
                </div>
                <div className={styles.part + " " + styles.reversed}>
                    <div className={styles.circle}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 500 500" xmlSpace="preserve">
                    <g id="border">
                        <path class="st0" style={{fill:"#FFFFFF",stroke:"#FFFFFF",strokeWidth:"10",strokeMiterlimit:"10"}} d="M439.3,188.3c-19.7-32.9-39.1-46.2-51.3-51.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.7-0.3-1.4-0.6-2-0.8
                            c-0.1,0-0.1,0-0.2-0.1c-0.6-0.2-1.2-0.5-1.8-0.6c-0.1,0-0.1,0-0.2-0.1c-0.6-0.2-1.2-0.4-1.7-0.5c0,0,0,0-0.1,0
                            c-0.5-0.2-1-0.3-1.5-0.4c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.9-0.2-1.3-0.3c0,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.2-1.1-0.2c0,0,0,0,0,0
                            c-0.3-0.1-0.6-0.1-0.9-0.2c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.7-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.6-0.1-0.9-0.1-0.9-0.1
                            c-15.4-38.1-83.6-54.1-83.6-54.1l-42.9-1.4c-10.1,0.6-45.9,8.8-84.8,25.7v0c0,0,0,0,0,0c-1.6,0.7-3.3,1.4-4.9,2.2c0,0,0,0,0,0v0
                            c-32.9,14.8-67.4,35.7-90.1,63.4v0c0,0,0,0,0,0c-1.4,1.6-2.7,3.3-3.9,5c0,0,0,0,0,0l0,0c-17,22.7-25.9,49.7-20.2,81.3
                            c1.2,6.5,2.5,12.5,3.8,18.1l0,0c0,0,0,0,0,0c24,99.3,75.5,77.5,78,76.4c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0s54.6,44,98.9,31.9
                            c20.1-5.5,29.5-10.1,33.9-13.6h0c0,0,0,0,0,0c2.7-2.1,3.5-3.7,3.6-4.9c0,0,0,0,0,0c0-0.2,0-0.3,0-0.4v0l0,0
                            c-0.1-0.8-0.4-1.2-0.4-1.2c20.1,9.4,31.2,13.2,44.8,26.5c13.7,13.3,34.3,33.9,34.3,33.9l18.6-5.1l-14.8-31.1c0,0,23.6,13,57.6-22.6
                            c25.3-26.4,24.9-50.9,23-61.7h0c0,0,0,0,0,0c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.1,0-0.2c-0.2-0.8-0.3-1.5-0.5-2.1
                            c0-0.1,0-0.2-0.1-0.2c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1,0-0.1-0.1-0.2
                            c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0h0c-0.1-0.4-0.2-0.7-0.2-0.7C455,273.1,471.6,242.4,439.3,188.3z"/>
                    </g>
                    <g id="object" xmlnsCc="http://creativecommons.org/ns#" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape" xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlnsSvg="http://www.w3.org/2000/svg">
                        <g>
                            <path class="st1" style={{fill:"var(--main-color)"}} d="M126.8,349.9c0,0-59.6,28.4-82-94.5s177.7-175.9,204-177.5l42.9,1.4c0,0,68.2,16,83.6,54.1
                                c0,0,31.5,0.9,63.9,55s15.7,84.8-14.4,107.5c0,0,12.5,32-21.6,67.5s-57.6,22.6-57.6,22.6l14.8,31.1l-18.6,5.1
                                c0,0-20.6-20.6-34.3-33.9c-13.7-13.3-24.7-17.1-44.8-26.5c0,0,7.2,8-37.1,20.1C181.4,393.9,126.8,349.9,126.8,349.9z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M380.9,137.7c6.4,5.6,10.2,9,6.2,19.4c-2.2,5.6-15.6,8.7-26.6,9.7c-1.2-2.4-3.6-4-6.4-4
                                c-3.9,0-7.1,3.2-7.1,7.1c0,3.9,3.2,7.1,7.1,7.1c3.1,0,5.8-2,6.7-4.8c10-0.9,27.7-3.9,31.3-13.2c4.4-11.4,0.9-17.3-4.1-22.2
                                c-5.9-2.6-10-3.3-11.8-3.5C377.8,134.9,379.4,136.3,380.9,137.7z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M426.4,301.7c-0.4-2.5-0.9-4.3-1.2-5.2c-5,0.4-19.5,0.6-30.2-1.9c-10.7-2.5-19.2-9.6-23.6-13.9
                                c0.2-0.7,0.4-1.5,0.4-2.3c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1c0,3.9,3.2,7.1,7.1,7.1c1.2,0,2.3-0.3,3.2-0.8
                                c4.9,4.7,14.1,12.2,25.9,15c7.4,1.8,16.6,2.3,23.7,2.3C421.2,302,424.5,301.8,426.4,301.7z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M271.9,277.1c-3.2,0-5.9,2.2-6.8,5.1c-8.5,2.1-19.6,2.7-32.2-8c-6.5-5.5-12-18.2-14.1-23.5
                                c2.2-1.2,3.8-3.5,3.8-6.2c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1c0,3.1,2.1,5.8,4.9,6.7c1.7,4.5,7.9,20.1,16.2,27.1
                                c8.8,7.4,17,10,24.3,10.4c0,0.1-0.1,0.1-0.1,0.2c-3.5,7.3-6.9,14.1-22.6,14.6c-11.5,0.3-23.3-7.7-23.4-7.8l-3.1,4.4c0,0,0,0,0,0
                                c-5,5.3-12.2,4.3-26.3-2.8c-5.5-2.8-9.4-7.6-11.7-11.5c1.1-1.2,1.8-2.9,1.8-4.6c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1
                                c0,3.9,3.2,7.1,7.1,7.1c0.2,0,0.3,0,0.5-0.1c2.7,4.5,7.2,10.4,14.1,13.8c2.1,1.1,4.3,2.1,6.5,3.1c-0.8,8.4-8.1,21.5-23.2,33.5
                                c-9.1,7.3-32.4,11.1-32.6,11.1l0,0.1c0.3-0.1,0.4-0.2,0.4-0.2s2.1,1.7,5.8,4.3c7.9-1.7,22.4-5.4,29.7-11.2
                                c13.9-11,23.3-24.7,25-35.8c3.1,1,6.2,1.7,9.1,1.7c4.7,0,9-1.6,12.6-6c4.9,2.6,12.8,6,21,6c0.3,0,0.6,0,0.8,0
                                c19-0.5,23.6-10,27.3-17.6c0.5-0.9,0.9-1.8,1.3-2.7c2.1-0.2,4.1-0.6,5.9-1c1.2,2.2,3.5,3.7,6.2,3.7c3.9,0,7.1-3.2,7.1-7.1
                                C279,280.3,275.8,277.1,271.9,277.1z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M241.9,355.8c-2.6-1.7-5.4-5.6-7.5-9.1c1-1.2,1.7-2.8,1.7-4.5c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1
                                c0,3.9,3.2,7.1,7.1,7.1c0.2,0,0.4,0,0.7-0.1c2.3,3.9,5.6,8.7,9.3,11.1c6.8,4.5,14.3,7.3,20.7,7.9c3.1-2.4,3.6-4.2,3.6-5.3
                                C258.1,363.2,250,361.2,241.9,355.8z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M251.3,134.6c-3.9,0-7.1,3.2-7.1,7.1c0,1.2,0.3,2.4,0.9,3.4c-3.1,2-8,3.5-13.8,3.4
                                c-4.6-0.1-20.1-1.7-24.6-19.4c4.6-3.2,7.7-7.5,8.4-11.6c0.1,0,0.2,0,0.3,0c3.9,0,7.1-3.2,7.1-7.1c0-3.9-3.2-7.1-7.1-7.1
                                c-3.9,0-7.1,3.2-7.1,7.1c0,1.9,0.8,3.7,2,4.9l-0.5,0.1c0.4,3.4-4,9-11.2,11.9c-3.7,1.5-16.4,5.3-25.9-7.5
                                c-4.5-6-7.6-13.7-8.6-16.4c-1.6,0.7-3.3,1.4-5,2.2c1.2,3.2,4.4,11,9.2,17.5c5.6,7.5,13.2,11.5,21.4,11.5c3.5,0,7.2-0.7,10.8-2.2
                                c0.4-0.2,0.8-0.4,1.2-0.5c4,13.6,14.9,21.8,29.4,22c0.2,0,0.4,0,0.6,0c7.2,0,13.6-2.1,17.7-5.4c0.6,0.1,1.2,0.2,1.8,0.2
                                c3.9,0,7.1-3.2,7.1-7.1C258.4,137.8,255.2,134.6,251.3,134.6z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M102.8,192c-2.9,0-5.4,1.7-6.5,4.2c-4.6,0.7-10.4-0.1-17.3-7.3c-5.2-5.4-9.9-19.1-10.1-19.8
                                c-1.4,1.6-2.7,3.3-3.9,5c1.9,4.9,5.7,13.9,10.1,18.5c7,7.3,13.3,9.2,18.5,9.2c0.9,0,1.7-0.1,2.5-0.2c1,2.7,3.6,4.6,6.6,4.6
                                c3.9,0,7.1-3.2,7.1-7.1C109.9,195.2,106.7,192,102.8,192z"/>
                            <path class="st2" style={{fill:"#ffff00"}} d="M111.3,259c-3.9,0-7.1,3.2-7.1,7.1c0,0.4,0.1,0.8,0.1,1.2c-5.4,1.7-12.4,2.8-18.4-2.7
                                c-3.6-3.3-5.1-9.1-5.8-12.7c1.6-1.3,2.6-3.3,2.6-5.5c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1c0,3.7,2.8,6.7,6.4,7
                                c0.8,4,2.6,10.1,6.4,14.2c-2.7,5.3-7.2,13.1-16.9,13.1c-0.1,0-0.2,0-0.2,0c-7.2-0.1-15-7.5-15.1-7.6l-0.3,0.4
                                c0.6,2.7,1.3,5.3,2,7.7c3.5,2.4,8.4,4.8,13.4,4.9c0.1,0,0.2,0,0.3,0c12.2,0,18.1-9.2,21.2-15.1c3.6,2.2,7.3,3,10.8,3
                                c4.1,0,7.9-1.1,10.9-2.1c1.1,0.8,2.5,1.2,4,1.2c3.9,0,7.1-3.2,7.1-7.1C118.4,262.2,115.2,259,111.3,259z"/>
                        </g>
                    </g>
                    </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>الإطار الأخلاقي</h3>
                        <p>
                            نحن نعطي الأولوية للاستخدام الأخلاقي للذكاء الاصطناعي، مضمونين أن حلولنا
                            تم تطويرها وتنفيذها وفقًا لأعلى معايير النزاهة والمسؤولية.
                        </p>
                    </div>
                </div>
                <div className={styles.part}>
                    <div className={styles.circle}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 392.533 392.533" xmlSpace="preserve">
                            <path style={{fill:"#FFFFFF"}} d="M196.267,21.786c-96.194,0-174.481,78.287-174.481,174.481c0,15.321,2.004,30.19,5.689,44.347h114.23
                                c5.107-25.406,27.604-44.671,54.497-44.671c7.046,0,13.77,1.293,19.911,3.685l51.459-82.23c3.168-5.107,9.891-6.659,14.998-3.491
                                s6.659,9.891,3.491,14.998l-51.459,82.36c8.145,7.758,13.834,17.842,16.162,29.22h114.23c3.685-14.222,5.689-29.026,5.689-44.347
                                C370.747,100.073,292.461,21.786,196.267,21.786z"/>
                            <path style={{fill:"#FFC10D"}} d="M345.406,200.339c0,13.77-2.004,27.087-5.495,39.758h-89.277
                                c-2.327-11.184-8.081-21.139-16.032-28.768l51.523-82.295c0.323-0.453,0.517-1.034,0.776-1.552
                                c13.511,17.648,21.657,39.24,21.657,62.642c0,6.012,4.848,10.861,10.925,10.861c6.012,0,10.925-4.848,10.925-10.861
                                c0-70.788-60.121-128.453-134.077-128.453S62.19,119.337,62.19,190.19c0,6.012,4.848,10.861,10.925,10.861
                                c6.012,0,10.925-4.848,10.925-10.861c0-58.828,50.36-106.537,112.291-106.537c29.931,0,57.018,11.184,77.188,29.35
                                c-2.327,0.776-4.396,2.327-5.818,4.525l-51.394,82.23c-6.206-2.392-12.929-3.685-19.911-3.685
                                c-26.699,0-49.131,18.941-54.368,44.154H52.622c-3.491-12.671-5.495-25.988-5.495-39.758c0-82.36,66.78-149.139,149.139-149.139
                                S345.406,117.98,345.406,200.339z"/>
                            <path style={{fill:"#FFFFFF"}} d="M196.267,217.729c-14.804,0-27.41,9.568-32,22.885h63.935
                                C223.677,227.426,211.135,217.729,196.267,217.729z"/>
                            <path style={{fill:"#56ACE0"}} d="M34.909,262.465c26.117,63.418,88.566,108.283,161.358,108.283s135.24-44.8,161.358-108.283
                                L34.909,262.465L34.909,262.465z"/>
                            <g>
                                <path style={{fill:"#194F82"}} d="M196.267,0C87.984,0,0,87.984,0,196.267s87.984,196.267,196.267,196.267
                                    s196.267-87.984,196.267-196.267S304.549,0,196.267,0z M196.267,370.747c-72.792,0-135.24-44.8-161.358-108.283h322.78
                                    C331.507,325.947,269.059,370.747,196.267,370.747z M164.267,240.614c4.59-13.317,17.131-22.885,32-22.885
                                    c14.869,0,27.41,9.568,31.935,22.885H164.267z M250.764,240.614c-2.263-11.378-8.016-21.463-16.162-29.22l51.523-82.36
                                    c0.323-0.453,0.517-1.034,0.776-1.552c13.511,17.648,21.657,39.24,21.657,62.642c0,6.012,4.848,10.925,10.925,10.925
                                    c6.077,0,10.925-4.848,10.925-10.925c0-70.788-60.121-128.453-134.077-128.453S62.19,119.273,62.19,190.19
                                    c0,6.012,4.848,10.925,10.925,10.925s10.925-4.848,10.925-10.925c0-58.828,50.36-106.537,112.291-106.537
                                    c29.931,0,57.018,11.184,77.188,29.35c-2.327,0.776-4.396,2.327-5.818,4.59l-51.394,82.166c-6.206-2.392-12.929-3.685-19.911-3.685
                                    c-26.893,0-49.455,19.265-54.497,44.671H27.604c-3.685-14.222-5.689-29.026-5.689-44.347
                                    C21.851,100.137,100.137,21.85,196.331,21.85s174.416,78.222,174.416,174.416c0,15.321-2.004,30.19-5.689,44.347H250.764z"/>
                                <path style={{fill:"#194F82"}} d="M131.62,321.358h129.293c6.012,0,10.925-4.848,10.925-10.925c0-6.012-4.848-10.925-10.925-10.925
                                    H131.62c-6.012,0-10.925,4.848-10.925,10.925S125.543,321.358,131.62,321.358z"/>
                            </g>
                        </svg>
                    </div>
                    <div className={styles.text}>
                        <h3>توجيه الأثر</h3>
                        <p>
                            نحن ملتزمون بإيجاد حلول ليس فقط لحل المشاكل الفورية ولكن أيضًا لديها
                            تأثير إيجابي دائم على المجتمع العماني والاقتصاد.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Why