import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./footer.module.css"
import { useSelector } from "react-redux"
import { selectLanguage } from "../../store/languageSlice"

const Footer = () => {
    const language = useSelector(selectLanguage)
    return (
    <div className={styles.footer}>
        <div className={styles.social}>
            <a href="https://api.whatsapp.com/send?phone=0096897755907" target="_blank">
                <span>
                    <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                </span>
            </a>
            <a href="tel:0096897755907">
                <span>
                    <FontAwesomeIcon icon="fa-solid fa-phone"/>
                </span>
            </a>
            <a href="https://facebook.com">
                <span>
                    <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                </span>
            </a>
            <a href="https://linkedin.com">
                <span>
                    <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
                </span>
            </a>
        </div>
        <div className={styles.info}>
            <p language="en">Al Ghubrah, Musact, Oman</p>
            <p language="ar">الغبرة, مسقط, سلطنة عمان</p>
            <p>
                <a href="tel:0096897755907">
                    0096897755907
                </a>
            </p>
            <p>
            <a href="mailto:info@thriveom.ai">
                info@thriveom.ai
            </a>
            </p>
        </div>
    </div>
  )
}

export default Footer