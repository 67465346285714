import React from "react"
import Heading from "../Heading/Heading"
import styles from "./steps.module.css"
import Step from "./Step/Step"
import StepsScroll from "./StepsScroll/StepsScroll"


const Steps = () => {
  return (
    <div className={styles.steps} id="steps">
            {/* <Heading text={"How it works"} count={1} />
            <Heading arabic={true} text={"الخطوات"} count={1} /> */}
            <StepsScroll />
            <div language="en">
              <Step   
                  count={1} 
                  title={"Discover and Define"}
                  paragraph={"Kickstart your journey by identifying your goals and challenges, laying the groundwork for a tailored AI strategy."}
                  isReversed={true}
                >
                      <dotlottie-player src="https://lottie.host/cbab1bf0-9443-4f8d-8fa7-46489702ba56/xVHiC3sM2T.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
              </Step>
              <Step   
                  count={2} 
                  title={"Transform Data into a Lasting Asset"}
                  paragraph={"We elevate your data, turning it into a valuable asset that not only powers your AI solutions but also serves as a cornerstone for future innovations."}
                  isReversed={false}
                  >
                      <dotlottie-player src="https://lottie.host/051acd45-5049-4735-90bf-ea3a2e5960cc/zqNRyrzZi3.json" background="transparent" speed="0.5" loop autoplay></dotlottie-player>
              </Step>
              <Step   
                  count={3} 
                  title={"Integration and Growth"}
                  paragraph={"Integrate AI into your business effortlessly, with our ongoing support ensuring your solutions evolve and scale with your needs."}
                  isReversed={true}
                >

                  <dotlottie-player src="https://lottie.host/2c82dcfe-91ea-459e-9234-e89a144c9004/NRQ8XkD7iA.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
              </Step>
              <Step   
                  count={4} 
                  title={"AI Solution Crafting"}
                  paragraph={"Watch as we develop intuitive AI solutions that seamlessly align with your business goals, designed to deliver impactful results."}
                  isReversed={false}
                >
                  <dotlottie-player src="https://lottie.host/16f8d9fa-73af-4878-ac23-d1c995a1019c/BEfqnbapdI.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
              </Step>
            </div>
            <div language="ar">
              <Step   
                count={5} 
                title={"اكتشف وحدد"}
                paragraph={"ابدأ رحلتك من خلال تحديد أهدافك وتحدياتك، ووضع الأسس لاستراتيجية ذكاء اصطناعي مصممة خصيصًا."}
                isReversed={true}
                arabic={true}
              >
                  {/* <dotlottie-player src="https://lottie.host/cbab1bf0-9443-4f8d-8fa7-46489702ba56/xVHiC3sM2T.json" background="transparent" speed="1" loop autoplay></dotlottie-player> */}
                  <dotlottie-player src="https://lottie.host/cbab1bf0-9443-4f8d-8fa7-46489702ba56/xVHiC3sM2T.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
              </Step>
              <Step   
                  count={6} 
                  title={"تحويل البيانات إلى أصل دائم"}
                  paragraph={"نرفع مستوى بياناتك، محولينها إلى أصل قيم يُشغل حلولك في مجال الذكاء الاصطناعي ويعمل أيضًا كركن للابتكارات المستقبلية."}
                  isReversed={false}
                  arabic={true}
                >
                  {/* <dotlottie-player src="https://lottie.host/051acd45-5049-4735-90bf-ea3a2e5960cc/zqNRyrzZi3.json" background="transparent" speed="0.5" loop autoplay></dotlottie-player> */}
                  <dotlottie-player src="https://lottie.host/051acd45-5049-4735-90bf-ea3a2e5960cc/zqNRyrzZi3.json" background="transparent" speed="0.5" loop autoplay></dotlottie-player>
              </Step>
              <Step   
                  count={7} 
                  title={"التكامل والنمو"}
                  paragraph={"ادمج الذكاء الاصطناعي في عملك بسهولة، مع دعمنا المستمر الذي يضمن تطور وتوسع حلولك مع احتياجاتك."}
                  isReversed={true}
                  arabic={true}
                >
                  <dotlottie-player src="https://lottie.host/2c82dcfe-91ea-459e-9234-e89a144c9004/NRQ8XkD7iA.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
              </Step>
              <Step   
                  count={8} 
                  title={"صياغة حلول الذكاء الاصطناعي"}
                  paragraph={"انظر كيف نقوم بتطوير حلول ذكاء اصطناعية بديهية تتماشى بسلاسة مع أهداف عملك، مصممة لتحقيق نتائج ذات تأثير."}
                  isReversed={false}
                  arabic={true}
                >
                  {/* <dotlottie-player src="https://lottie.host/724f7459-5b40-4445-88cf-f2696846fbb6/C5Eq4GjcWC.json" background="transparent" speed="1" loop autoplay></dotlottie-player> */}
                  {/* <dotlottie-player src="https://lottie.host/7c396e20-b673-4e49-8ba4-5011e26a0ad5/r2VB2fgaMq.json" background="transparent" speed="1" loop autoplay></dotlottie-player> */}
                  <dotlottie-player src="https://lottie.host/16f8d9fa-73af-4878-ac23-d1c995a1019c/BEfqnbapdI.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
              </Step>
            </div>

        {/* </div> */}
    </div>
  )
}

export default Steps