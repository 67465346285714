  import styles from "./developer-footer.module.css"

const DeveloperFooter = () => {
  return (
    <div className={styles.footer}>
                <p>
                  &copy; 2024
                  By: <a href="https://thriveom.ai">
                      Thrive AI
                  </a>
                </p>
    </div>
  )
}

export default DeveloperFooter